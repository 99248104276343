import React from 'react';

const Loader = (props) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className={`loader-wrapper ${props.className || ''}`}>
          <div className="centered">
            <div className="loadersmall"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
