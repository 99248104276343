import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';

import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import cartSlice from './cartSlice';

const persistConfig = {
  key: 'root',
  storage
};

const combine = combineReducers({ authSlice, cartSlice });

const persistedReducer = persistReducer(persistConfig, combine);
const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

export { store, persistor };
