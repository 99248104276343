import { createSlice } from '@reduxjs/toolkit';
import { getCartTotalQty } from '../services/auth-header';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: { qty: 0, currency: '', metaTitle: '' },
  reducers: {
    cartLength: (state, action) => {
      state.qty = getCartTotalQty();
    },
    currencyAction: (state, action) => {
      state.currency = action.payload;
    },
    metaTitleAction: (state, action) => {
      state.metaTitle = action.payload;
    }
  }
});

export const { cartLength, currencyAction, metaTitleAction } = cartSlice.actions;
export default cartSlice.reducer;
