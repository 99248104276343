import { store } from '../reducer/store';
import ReactPixel from 'react-facebook-pixel';
export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user) {
    return { Authorization: 'Bearer ' + user };
  } else {
    return {};
  }
}

const cart_key = 'cart';

export const addProductToCart = (id, qty, variations) => {
  let cart = getCartProductList();

  let obj = {
    id: id,
    qty: qty,
    variations: variations
  };

  let index = cart.findIndex((e) => e.id == id);

  if (index == -1) {
    cart = [...cart, obj];
  } else {
    cart[index] = obj;
  }

  updateCart(cart);
};

export const updateCart = (array) => {
  localStorage.setItem(cart_key, JSON.stringify(array));
};

export const getCartTotalQty = () => {
  let cart = getCartProductList();

  let sum = cart.reduce(function (prev, current) {
    return prev + +current.qty;
  }, 0);

  return sum;
};

export const getCartTotalQtyByProduct = (id) => {
  let cart = getCartProductList();

  let sum = cart.reduce(function (prev, current) {
    return current.id == id ? prev + current.qty : prev;
  }, 0);

  return sum;
};

export const getHasProductInCart = (id) => {
  let cart = getCartProductList();

  let sum = cart.reduce(function (prev, current) {
    return current.id === id ? prev + current.qty : prev;
  }, 0);

  return sum != 0;
};

export const getCartProductList = (string = false) => {
  if (string) return localStorage.getItem(cart_key) ? localStorage.getItem(cart_key) : '';

  const cart = JSON.parse(localStorage.getItem(cart_key) ? localStorage.getItem(cart_key) : '[]');

  return cart || [];
};

export const updateQty = (id, qty) => {
  let cart = getCartProductList();

  let index = cart.findIndex((e) => e.id == id);

  if (index == -1) return;

  cart[index].qty = qty;

  updateCart(cart);
};

export const currencySymbol = () => {
  // const currency = localStorage.getItem('currency_symbol');
  const currency = store?.getState()?.cartSlice?.currency;

  return (
    <span
      className=""
      dangerouslySetInnerHTML={{
        __html: currency
      }}
    ></span>
  );
};

export const currencySymbolUniCode = () => {
  // let currency = localStorage.getItem('currency_symbol');
  let currency = store?.getState()?.cartSlice?.currency;
  currency = currency ? currency.trim() : '';

  if (currency == '&#8377;') return '\u20B9';
  else if (currency == '$') return '$';

  return currency || '';
};

export const currencySymbolApi = (symbol) => {
  return (
    <span
      className=""
      dangerouslySetInnerHTML={{
        __html: symbol
      }}
    ></span>
  );
};

export const ViewContentAction = (title) => {
  ReactPixel.fbq('track', 'ViewContent', { content_name: title });
};
