import axios from 'axios';
import { API_URL } from './ApiEndPoint';
import { toast } from 'react-toastify';
import { store } from '../reducer/store';
import { logout } from '../reducer/authSlice';

const instance = axios.create({
  baseURL: API_URL
});

instance.interceptors.request.use((req) => {
  // const tk = JSON.parse(localStorage.getItem('user'));
  const tk = store?.getState()?.authSlice?.token;
  req.headers.Authorization = `Bearer ${tk}`;
  return req;
});

instance.interceptors.response.use(
  async (response) => {
    if (response.data.status === 'RC200') {
      return response;
    }
    if (response.data.status === 'RC100') {
      //   toast.error(response.data.message);
    }
    if (response.data.status === 'RC300') {
      localStorage.removeItem('user');
      store?.dispatch(logout());
      window.location.href = '/login';
      toast.error(response.data.message);
    } else {
      return response;
    }
  },
  (err) => {
    // if (err?.response?.status === 'RC100') {
    //   toast.error(err?.response?.data?.message || err?.message);
    // }
    // throw new Error(err);
  }
);

export default instance;

const fetcher = (url) => instance(url).then((res) => res);

export { fetcher };
