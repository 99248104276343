import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './reducer/store';
import { Provider } from 'react-redux';
import './assets/css/bootstrap.min.css';
import './assets/css/styles.css';
import './assets/css/component-product.css';
import './assets/css/style.css';
import './assets/css/skin-2.css';
import './assets/css/header-4.css';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/tab';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.min.css';
import 'reactjs-popup/dist/index.css';
import '@smastrom/react-rating/style.css';
import './assets/css/slick.css';
import './assets/css/slick-theme.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './assets/css/skin-4.css';
import './assets/css/popup.css';
import './index.css';
import ReactPixel from 'react-facebook-pixel';
const root = ReactDOM.createRoot(document.getElementById('root'));

// ReactPixel.init('375405965560190');
// ReactPixel.pageView();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
